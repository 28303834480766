import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {
  hideMessage,
  showAuthLoader,
  userSignIn
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress";

const SignIn = () => {
  const dispatch = useDispatch();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });

  const onFinishFailed = errorInfo => {
  };

  const onFinish = values => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values, history));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div  style={{backgroundColor:"#cdcdd1"}} className="gx-app-logo-content">
            <div style={{backgroundColor:"#cdcdd1"}} className="gx-app-logo-content-bg">

            </div>
            <div className="gx-app-logo-wid">
              <div className="gx-app-logo">
                <img style={{maxWidth:"100%", borderRadius:10}} alt="example" src="/assets/images/logo-main.jpg"/>
              </div>

            </div>
            <div className="gx-app-logo">
              <h1>Sign In</h1>
              <p>Welcome back!</p>
              {/*<img style={{maxWidth:60}} alt="example" src="/assets/images/logo-white.png"/>*/}
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{remember: true}}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <Form.Item
                rules={[{required: true, message: 'The input is not valid E-mail!'}]} name="email">
                <Input type="email" placeholder="Email"/>
              </Form.Item>
              <Form.Item
                rules={[{required: true, message: 'Please input your Password!'}]} name="password">
                <Input type="password" placeholder="Password"/>
              </Form.Item>
              <Form.Item>
                <div className="d-flex justify-content-between">
                  <Checkbox>Remember Me</Checkbox>
                  <Link to={"/reset-password"}>Forgot Password</Link>
                </div>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit" block>
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </div>

          {loader ?
            <div className="gx-loader-view">
              <CircularProgress/>
            </div> : null}
          {showMessage ?
            message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
