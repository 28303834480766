import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import "../../styles/sidebat.css";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const currentUser = JSON.parse(localStorage.getItem("user"));

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <i className="icon icon-dasbhoard" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="customers">
              <Link to="/customers">
                <i className="icon icon-home" />
                <span>Manage Customers</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="agents">
              <Link to="/agents">
                <i className="icon icon-user-o" />
                <span>Manage Agents</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="manage transaction">
              <Link to="/transactions">
                <i className="icon icon-litcoin" />
                <span>Transactions</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="trackers">
              <Link to="/trackers">
                <i className="icon icon-lock-screen" />
                <span>All Trackers</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="tariffs">
              <Link to="/tariffs">
                <i className="icon icon-lock-screen" />
                <span>Tariffs</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="manage users">
              <Link to="/users">
                <i className="icon icon-profile" />
                <span>Users</span>
              </Link>
            </Menu.Item>

            {currentUser.role === "ADMIN" && (
              <Menu.SubMenu
                key="settings"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-setting" />
                    <span>System Settings</span>
                  </span>
                }
              >
                <Menu.Item key="settings/tracker-modals">
                  <Link to="/settings/tracker-modals">
                    <span>Trackers Models</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="settings/app-configs">
                  <Link to="/settings/app-configs">
                    <span>Configurations</span>
                  </Link>
                </Menu.Item>

                {/* <Menu.Item key="setting/commission-configs">
                  <Link to="/setting/commission-configs">
                    <span>Commission Configurations</span>
                  </Link>
                </Menu.Item> */}
              </Menu.SubMenu>
            )}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
