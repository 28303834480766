import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (

  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <Route
        path={`${match.url}customers/:id`}
        component={asyncComponent(() => import("./Customer/customerDetails"))}
      />
      <Route
        path={`${match.url}customers`}
        component={asyncComponent(() => import("./Customer"))}
      />
      <Route
        path={`${match.url}add-customer`}
        component={asyncComponent(() => import("./Customer/addCustomer"))}
      />
      <Route
        path={`${match.url}agents`}
        component={asyncComponent(() => import("./Agent"))}
      />
      <Route
        path={`${match.url}transactions`}
        component={asyncComponent(() => import("./Transactions"))}
      />
      <Route
        path={`${match.url}tariffs`}
        component={asyncComponent(() => import("./Tariff"))}
      />
      <Route
        path={`${match.url}trackers`}
        component={asyncComponent(() => import("./Tracker"))}
      />
      <Route
        path={`${match.url}users`}
        component={asyncComponent(() => import("./Users"))}
      />
      <Route
        path={`${match.url}settings/tracker-modals`}
        component={asyncComponent(() => import("./TrackersModal"))}
      />
      <Route
        path={`${match.url}settings/app-configs`}
        component={asyncComponent(() => import("./AppConfig"))}
      />
      <Route
        path={`${match.url}users`}
        component={asyncComponent(() => import("./Users"))}
      />
      <Route
        path={`${match.url}change-password`}
        component={asyncComponent(() =>
          import("../containers/FirstLoginPasswordReset")
        )}
      />
    </Switch>
  </div>
);


export default App;