import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SIGNIN_USER, SIGNOUT_USER } from "constants/ActionTypes";
import {
  resetPasswordSuccess,
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
} from "../../appRedux/actions/Auth";
import axios_ from "../../util/axios";
import { RESET_PASSWORD } from "../../constants/ActionTypes";

const loginRequest = async (email, password) => {
  const payload = {
    email,
    password,
  };

  return await axios_
    .post("/auth/login", payload)
    .then((authUser) => {
      return authUser.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

const resetPasswordRequest = async (email) => {
  const payload = {
    email,
  };

  return await axios_
    .post("/auth/password-reset", payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

const passwordResetVerification = async (email, password, confirmPassword) => {
  const payload = {
    email,
    password,
    confirmPassword,
  };

  return await axios_
    .post("/auth/password-reset-verification", payload)
    .then((authUser) => {
      return authUser.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
// const signOutRequest = async () =>
//   await  auth.signOut()
//     .then(authUser => authUser)
//     .catch(error => error);

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(loginRequest, email, password);
    console.log(signInUser)
    if (signInUser.token) {
      localStorage.setItem("user_id", signInUser.user.id);
      localStorage.setItem("token", signInUser.token);
      localStorage.setItem("user", JSON.stringify(signInUser.user));
      yield put(userSignInSuccess(signInUser));
    } else if(signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    }
  } catch (error) {
    console.log(error)
    yield put(
      showAuthMessage("Authentication failed. Invalid username or password.")
    );
  }
}

function* resetPasswordInit({ payload }) {
  const { email } = payload;
  try {
    const rp = yield call(resetPasswordRequest, email);
    if (rp) {
      yield put(resetPasswordSuccess());
    } else {
      yield put(showAuthMessage(rp));
    }
  } catch (error) {
    yield put(showAuthMessage("Action failed."));
  }
}

function* signOut() {
  try {
    const signOutUser = null;
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      yield put(userSignOutSuccess(signOutUser));
      window.location.reload();
    } else {
      yield put(showAuthMessage(signOutUser.message));
      localStorage.removeItem("user_id");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      yield put(userSignOutSuccess(signOutUser));
      window.location.reload();
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPasswordInit);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser)]);
}
